import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { useState } from 'react'
import { BasicResponse, Customer, HttpError } from '@eo-storefronts/eo-core'
import { CustomerProfileFormInterface } from '~/src/types/forms/CustomerProfileFormInterface'
import RoutesEnum, { RoutesEnumType } from '~/src/router/enums/routes.enum'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import useGetQueryParam from '~/src/hooks/router/useGetQueryParam'
import Params from '~/src/router/enums/params.enum'
import { FALLBACK_PAGE_SELECTOR } from '~/src/stores/router'
import { updateCustomer } from '~/src/services/CustomerService'
import { useSnackbar } from 'notistack'
import { useTranslations } from '~/src/hooks/useTranslations'
import useFetchCustomer from '~/src/hooks/customer/useFetchCustomer'

interface ReturnsType {
  update(extraInfo: CustomerProfileFormInterface): Promise<BasicResponse | undefined | void>,

  data: Customer | undefined,
  errors: Record<string, string[]> | string | null,
  loading: boolean,
}

const useUpdateCustomerExtraInfo = (): ReturnsType => {
  const [ customer, setCustomer ] = useEoState(CUSTOMER_STATE)
  const fallbackPage = useEoValue(FALLBACK_PAGE_SELECTOR)
  const { fetch: fetchCustomer } = useFetchCustomer()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslations()
  const { params: redirectTo } = useGetQueryParam(Params.REDIRECT_TO)
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ errors, setErrors ] = useState<Record<string, string[]> | string | null>(null)
  const [ data, setData ] = useState<Customer | undefined>()

  const _handleOnSuccess = async () => {
    enqueueSnackbar(t('customer.updated'), { variant: 'success' })

    const updatedCustomer: Customer | undefined = await fetchCustomer()

    setData(updatedCustomer)
    updatedCustomer && setCustomer((state: Customer | null) => ({
      ...state,
      ...updatedCustomer
    }))


    if (redirectTo) {
      push(resolve(RoutesEnum[redirectTo as keyof RoutesEnumType]))

      return
    }

    push(resolve(fallbackPage))
  }

  const _handleOnError = (e: HttpError | unknown | undefined) => {
    enqueueSnackbar({ message: t('errors.defaultError'), variant: 'error' })

    const errors = (e as HttpError).errors

    if (errors) {
      setErrors(errors)
    }
  }

  const update = async ({
    email,
    phone,
    firstName,
    lastName
  }: CustomerProfileFormInterface): Promise<BasicResponse | undefined | void> => {
    setErrors(null)
    setData(undefined)
    setLoading(true)

    if (!customer) {
      return
    }

    return updateCustomer(
      customer.id,
      {
        ...customer,
        firstName: firstName,
        lastName: lastName,
        email,
        phone
      })
      .then(async (response: BasicResponse | undefined) => {
        if (!response) {
          return
        }

        await _handleOnSuccess()

        return response
      })
      .catch(_handleOnError)
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    update,
    data,
    loading,
    errors
  }
}

export default useUpdateCustomerExtraInfo
