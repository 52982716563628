import { useTranslations } from '~/src/hooks/useTranslations'
import { FormikProps } from 'formik'
import FormikTextField from '~/src/components/mui-wrappers/fields/FormikTextField'
import PhoneInput from '~/src/components/mui-wrappers/styled/phone-input'
import { Box } from '@mui/material'
import { CustomerProfileFormInterface } from '~/src/types/forms/CustomerProfileFormInterface'

interface Props {
  form: FormikProps<CustomerProfileFormInterface>,
  disabledFields: Record<keyof CustomerProfileFormInterface, boolean>,
}

const ExtraInfoCustomerFields = ({ form, disabledFields }: Props) => {
  const { t } = useTranslations()

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2
    }}>
      <FormikTextField
        TextFieldProps={{
          form,
          fullWidth: true,
          color: 'content',
          name: 'email',
          type: 'email',
          inputMode: 'email',
          variant: 'outlined',
          disabled: disabledFields.email,
          required: true,
          label: t('form.email')
        }}
      />

      <FormikTextField
        TextFieldProps={{
          form,
          fullWidth: true,
          color: 'content',
          name: 'firstName',
          label: t('form.firstname'),
          required: true,
          variant:'outlined',
          disabled: disabledFields.firstName
        }}
      />

      <FormikTextField
        TextFieldProps={{
          form,
          fullWidth: true,
          color: 'content',
          name: 'lastName',
          label: t('form.lastname'),
          required: true,
          variant:'outlined',
          disabled: disabledFields.lastName
        }}
      />

      <PhoneInput
        PhoneInputProps={{
          form,
          fullWidth: true,
          color: 'content',
          name: 'phone',
          label: t('form.phone'),
          required: true,
          variant: 'outlined',
          disabled: disabledFields.phone
        }}
      />
    </Box>
  )
}

export default ExtraInfoCustomerFields
