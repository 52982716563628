import { Box } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'
import ExtraInfoCustomerForm from '~/src/components/auth/extra-info/customer/ExtraInfoCustomerForm'

const ExtraInfoCustomer = () => {
  const { t } = useTranslations()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        gap: 5
      }}
    >
      <Box>
        <Title variant='h3'>
          {t('extraInfo.customer.title')}
        </Title>
        <Title variant='h6'>
          {t('extraInfo.customer.subTitle')}
        </Title>
      </Box>
      <ExtraInfoCustomerForm />
    </Box>
  )
}

export default ExtraInfoCustomer
